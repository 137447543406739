
// Copyright: Panshak Solomon
// A.P. Leventis Ornithological Research Institute.
// University of Jos Biological Conservatory
// All right reserved
// ©2021 and beyond


import React, { useEffect} from 'react'
import SnackbarProvider from 'react-simple-snackbar'
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom'

import Footer from './components/Footer/Footer'
import HomePage from './pages/HomePage'
import Profile from './pages/Profile/Profile'
import Users from './pages/Users/Users'
import PubDetails from './pages/PubDetails/PubDetails'
import ProposalDetail from './pages/ProposalDetail/ProposalDetail'
import NewsDetails from './pages/NewsDetails/NewsDetails'
import DocumentDetails from './pages/DocumentDetails/DocumentDetails'
import ResearchDetails from './pages/ResearchDetails/ResearchDetails'
import ThesesDetails from './pages/ThesesDetails/ThesesDetails'
import AuthPage from './pages/AuthPage'
import Publications from './pages/Publications/Publications'
import Proposals from './pages/Proposals/Proposals'
import Documents from './pages/Documents/Documents'
import News from './pages/News/News'
import Team from './pages/Team/Team'
import Nav from './components/Nav/Nav'
import Alumni from './pages/People/Alumni/Alumni'
import Staff from './pages/People/Staff'
import BoardMembers from './pages/People/BoardMembers'
import Affiliates from './pages/People/Affiliates'
import Students from './pages/People/Students'
import Research from './pages/Research/Research'
import Theses from './pages/Theses/Theses'
import AboutUs from './pages/AboutUs'
import Facilities from './pages/Facilities'
import Library from './pages/Library'
import MscProgram from './pages/MscProgram'
import ContactPage from './pages/ContactPage'
import Forgot from './pages/Password/Forgot'
import Reset from './pages/Password/Reset'
import Moderator from './pages/Moderator/Moderator'
import Events from './pages/Events/Events'
import ManageProfiles from './pages/ManageProfiles/ManageProfiles'
import SpeciesBoard from './pages/Birds/SpeciesBoard/SpeciesBoard'
import BirdPage from './pages/Birds/BirdPage/BirdPage'
import SpeciesDetail from './pages/Birds/SpeciesDetail/SpeciesDetail'
import NewSpecies from './pages/Birds/SpeciesBoard/AddPost/NewSpecies'
import BirdList from './pages/Birds/BirdList/BirdList'
import Equipment from './pages/Equipment/Equipment'
import Datazone from './pages/Datazone/Home/Home'
import PointCount from './pages/Datazone/PointCount/PointCount'
import Founder from './pages/Founder/Founder'
import CapacityBuilding from './pages/capacityBuilding/CapacityBuilding'



function App() {

  const user = JSON.parse(localStorage.getItem('profile'))

  function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// https://emerald2.doveserver.com:2096

  return (
    <div>
      <BrowserRouter>
      <SnackbarProvider>
      <ScrollToTop />
      {/* <Navbarmenu /> */}
      <Nav />
      {/* <NavBar /> */}
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/about" exact component={AboutUs} />
          <Route path="/founder" exact component={Founder} />
          <Route path="/facilities" exact component={Facilities} />
          <Route path="/library" exact component={Library} />
          <Route path="/capacity-building" exact component={CapacityBuilding} />
          <Route path="/msc-program" exact component={MscProgram} />
          <Route path="/team" exact component={Team} />
          <Route path="/people/alumni" exact component={Alumni} />
          <Route path="/people/staff" exact component={Staff} />
          <Route path="/people/students" exact component={Students} />
          <Route path="/people/affiliates" exact component={Affiliates} />
          <Route path="/people/board-members" exact component={BoardMembers} />
          <Route path="/moderator" exact component={Moderator} />
          <Route path="/database" exact component={ManageProfiles} />
          <Route path="/member/:slug" exact component={Users} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/publications" exact component={Publications} />
          <Route path="/proposals" exact component={Proposals} />
          <Route path="/documents" exact component={Documents} />
          <Route path="/news" exact component={News} />
          <Route path="/events" exact component={Events} />
          <Route path="/research" exact component={Research} />
          <Route path="/theses" exact component={Theses} />
          <Route path="/news/:slug" exact component={NewsDetails} />
          <Route path="/research/:slug" exact component={ResearchDetails} />
          <Route path="/documents/:slug" exact component={DocumentDetails} />
          <Route path="/theses/:slug" exact component={ThesesDetails} />
          <Route path="/publications/:slug" exact component={PubDetails} />
          <Route path="/proposals/:slug" exact component={ProposalDetail} />
          <Route path="/auth" exact component={() => (!user ? <AuthPage /> : <Redirect to="/" />)} />
          <Route path="/forgot" exact component={Forgot} />
          <Route path="/reset/:token" exact component={Reset} />
          <Route path="/species" exact component={SpeciesBoard} />
          <Route path="/birds/:slug" exact component={SpeciesDetail} />
          <Route path="/newspecies" exact component={NewSpecies} />
          <Route path="/edit/species/:slug" exact component={NewSpecies} />
          <Route path="/birdlist" exact component={BirdList} />
          <Route path="/equipment" exact component={Equipment} />

          <Route path="/datazone" exact component={Datazone} />
          <Route path="/datazone/birds" exact component={BirdPage} />
          <Route path="/datazone/pointcount" exact component={PointCount} />

        </Switch>
        <Footer />
        </SnackbarProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
